import axios from "axios";

const config = {
  loadConfig: function () {
    return new Promise((resolve, reject) => {
      axios
        .get(`/config/config.json`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
};

export { config };
