<template>
  <div class="sub-timeline-container">
    <div v-if="loading">
      <alert class="my-2 light-shadow" />
    </div>
    <div
      v-if="!loading && timeLine && timeLine.length && !maxItems"
      class="d-flex flex-wrap"
    >
      <div class="col-9 pl-0 pr-3 col-sm-6 col-md-4 mb-2 filter-items">
        <select-control
          v-model="type"
          @input="checkSelectedTaskType()"
          :options="actionTypes"
          type="vertical"
          wrapClass="mb-0"
          inputClass="light-shadow"
        >
        </select-control>
      </div>
      <div class="col-9 pl-0 pr-3 col-sm-6 col-md-4 mb-2 filter-items">
        <select-control
          :disabled="!actionTypeRelatedToTask"
          v-model="selectedTaskType"
          :options="taskTypes"
          type="vertical"
          wrapClass="mb-0"
          inputClass="light-shadow"
        ></select-control>
        <i
          class="fa fa-info-circle ml-1 tooltip-info"
          v-tooltip.top="'Available for actions related to tasks'"
        ></i>
      </div>
      <div
        class="
          d-flex
          mb-4
          m-md-2
          mb-md-3
          ml-auto ml-md-auto
          align-items-end
          justify-content-end
        "
      >
        <button
          :class="`btn btn-md-sm ${
            listDisplay == 'timeline'
              ? 'btn-header-blue'
              : 'btn-outline-header-blue'
          }`"
          style="border-bottom-right-radius: 0; border-top-right-radius: 0"
          @click="setDisplayType('timeline')"
        >
          <i class="fa-solid fa-timeline"></i>
        </button>
        <button
          :class="`btn btn-md-sm ${
            listDisplay == 'list'
              ? 'btn-header-blue'
              : 'btn-outline-header-blue'
          }`"
          style="border-radius: 0"
          @click="setDisplayType('list')"
        >
          <i class="fas fa-list"></i>
        </button>
        <button
          :class="`btn btn-md-sm ${
            listDisplay == 'table'
              ? 'btn-header-blue'
              : 'btn-outline-header-blue'
          }`"
          style="border-bottom-left-radius: 0; border-top-left-radius: 0"
          @click="setDisplayType('table')"
        >
          <i class="fas fa-th"></i>
        </button>
      </div>
    </div>
    <alert
      class="my-2 light-shadow"
      v-if="!loading && filteredTimeline && filteredTimeline.length == 0"
      >No Items</alert
    >
    <div
      class="mb-0 overflow-auto border-0 p-2 p-lg-0"
      :class="[
        this.listDisplay == 'timeline'
          ? ''
          : 'card shadow-card mobile-tablet-no-card',
      ]"
      v-if="!loading && filteredTimeline && filteredTimeline.length > 0"
    >
      <div class="mb-0 table-container">
        <table
          class="
            table table-sm table-striped
            mb-0
            responsive-mobile-tablet-table
          "
          v-show="listDisplay == 'table'"
        >
          <thead>
            <tr>
              <th></th>
              <th class="timeline-date">Date</th>
              <th>Activity</th>
              <th v-if="!maxItems">Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="task in filteredTimeline" :key="task.id">
              <td class="px-4 py-2">
                <img
                  class="table-avatar-task-img rounded-circle"
                  :src="
                    (task.type == 'TAGGED_IN_TASK' ||
                    task.type == 'TAGGED_IN_TASK_UPDATE' ||
                    task.type == 'TAGGED_IN_TASK_REPLY'
                      ? task.subscriber.profile_picture
                      : task.publisher.profile_picture) ||
                    profileImagePlaceholder
                  "
                />
              </td>
              <td class="timeline-date">
                <b class="mobile-tablet-only mr-2">Date:</b
                >{{ formattedDate(task.due_date) }}
              </td>
              <td style="font-size: 14px">
                <b class="mobile-tablet-only mr-2 pt-0">Activity:</b>
                <div
                  v-html="task.description"
                  style="display: inline-block"
                ></div>
              </td>
              <td v-if="!maxItems">
                <div
                  class="task-type-header-container-table"
                  v-if="task.related_record_type"
                >
                  <b class="mobile-tablet-only mr-2 pt-0">Type:</b>
                  <div
                    :class="`task-type-header-table ellipsis ${task.className}`"
                  >
                    {{ task.displayType }}
                  </div>
                </div>
                <div
                  class="task-type-header-container-table"
                  v-if="!task.related_record_type"
                >
                  <b class="mobile-tablet-only mr-2 pt-0">Type:</b>
                  <i
                    class="fas fa-2x text-dark"
                    :class="
                      task.type == 'SHARED_IN_FOLDER'
                        ? 'fa-folder-open'
                        : task.type.indexOf('REACTION') > -1
                        ? 'fa-grin-stars'
                        : 'fa-file-alt'
                    "
                  ></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="accordion task-accordion"
          id="tasksAccordion"
          v-show="listDisplay == 'list'"
        >
          <div
            v-for="(task, taskIndex) in filteredTimeline"
            :key="task.id"
            class="card mb-0"
            :class="`item-index_${taskIndex}`"
          >
            <div
              class="card-header p-0"
              :id="`headingTask_${task.id}`"
              style="height: auto; min-height: 60px"
            >
              <h2 class="mb-0">
                <button
                  class="btn btn-link text-left w-100 shadow-none"
                  type="button"
                  data-toggle="collapse"
                  :class="!hasExpandFeature(task) ? 'cursor-default' : ''"
                  :data-target="
                    hasExpandFeature(task) && `#collapseTask_${task.id}`
                  "
                  aria-expanded="false"
                  :aria-controls="
                    hasExpandFeature(task) && `collapseTask_${task.id}`
                  "
                >
                  <div
                    class="
                      d-flex
                      heading-task-content
                      text-dark
                      align-items-start align-items-sm-center
                      pr-4
                    "
                    :class="task.related_record_type ? 'has-badge' : ''"
                  >
                    <img
                      class="avatar-task-img rounded-circle"
                      :src="
                        (task.type == 'TAGGED_IN_TASK' ||
                        task.type == 'TAGGED_IN_TASK_UPDATE' ||
                        task.type == 'TAGGED_IN_TASK_REPLY'
                          ? task.subscriber.profile_picture
                          : task.publisher.profile_picture) ||
                        profileImagePlaceholder
                      "
                    />
                    <p
                      class="task-action-type mb-0 ml-3 mr-xl-3 pr-2 pr-md-0"
                      v-html="
                        task.description +
                        '<small> on ' +
                        formattedDate(task.due_date) +
                        '</small>'
                      "
                    ></p>
                    <div
                      class="ml-auto task-type-header-container"
                      v-if="task.related_record_type"
                    >
                      <div
                        :class="`task-type-header ellipsis ${task.className}`"
                      >
                        {{ task.displayType }}
                      </div>
                    </div>
                    <div
                      class="ml-auto"
                      style="margin-right: -24px"
                      v-if="!task.related_record_type"
                    >
                      <i
                        class="fas fa-2x text-dark"
                        :class="
                          task.type == 'SHARED_IN_FOLDER'
                            ? 'fa-folder-open'
                            : task.type.indexOf('REACTION') > -1
                            ? 'fa-grin-stars'
                            : 'fa-file-alt'
                        "
                      ></i>
                    </div>
                  </div>
                  <i
                    class="fas fa-angle-down rotate-icon text-dark"
                    v-if="hasExpandFeature(task)"
                  ></i>
                </button>
              </h2>
            </div>
            <div
              :id="`collapseTask_${task.id}`"
              class="collapse"
              :class="[
                task.read_at || task.assignee_id != user.id ? '' : 'taskAlert',
              ]"
              :aria-labelledby="`headingTask_${task.id}`"
            >
              <div class="card-body" v-if="task.messageBody">
                <div class="task-title text-bold text-center">
                  <span v-if="!task.related_reply_id">
                    Subject: {{ task.related_record_name }}
                  </span>
                  <span v-if="task.related_reply_id">
                    Reply on task ({{ task.related_record_name }})
                  </span>
                </div>
                <br />
                <div
                  class="card shadow-none p-2 ck ck-content"
                  v-html="
                    $options.filters.nl2br(
                      $options.filters.slash2hr(task.messageBody)
                    )
                  "
                ></div>
                <div
                  v-if="task.link"
                  class="
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-center
                    py-4
                    px-2
                  "
                  style="background-color: #e6e0e069; border-radius: 4px"
                >
                  <button class="btn btn-black" @click="navigate(task.link)">
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="listDisplay == 'timeline'" class="timeline-task pl-1">
          <div class="timeline-dashboard-container d-flex">
            <div
              class="timeline-bar"
              :style="{ backgroundImage: `url(${timelineBarImg})` }"
            ></div>
            <div class="timeline-content">
              <h1 class="mb-0 pt-3">Timeline</h1>
              <div
                v-for="task in filteredTimeline"
                :key="task.id"
                class="position-relative"
              >
                <div class="image-info">
                  <img :src="timelineImgBorder" class="image-border" />
                  <img
                    class="avatar-task-img rounded-circle"
                    :src="
                      (task.type == 'TAGGED_IN_TASK' ||
                      task.type == 'TAGGED_IN_TASK_UPDATE' ||
                      task.type == 'TAGGED_IN_TASK_REPLY'
                        ? task.subscriber.profile_picture
                        : task.publisher.profile_picture) ||
                      profileImagePlaceholder
                    "
                  />
                </div>
                <div class="item-content">
                  <div class="timeline-date-info">
                    <p class="user-name mb-0">{{ task.related_record_name }}</p>
                    <p class="date-time mb-0">
                      {{ formattedDate(task.due_date) }}
                    </p>
                  </div>
                  <div class="timeline-subject">
                    <div
                      class="accordion task-accordion"
                      id="timeTasksAccordion"
                    >
                      <div
                        class="card-header p-0 border-0"
                        :id="`timeHeadingTask_${task.id}`"
                        style="height: auto; min-height: 60px"
                      >
                        <h2 class="mb-0">
                          <button
                            class="
                              btn btn-link
                              text-left
                              w-100
                              shadow-none
                              position-relative
                            "
                            type="button"
                            data-toggle="collapse"
                            :class="
                              !hasExpandFeature(task) ? 'cursor-default' : ''
                            "
                            :data-target="
                              hasExpandFeature(task) &&
                              `#timeCollapseTask_${task.id}`
                            "
                            aria-expanded="false"
                            :aria-controls="
                              hasExpandFeature(task) &&
                              `timeCollapseTask_${task.id}`
                            "
                          >
                            <div
                              class="
                                d-flex
                                heading-task-content
                                text-dark
                                align-items-start align-items-sm-center
                                pr-4
                              "
                              :class="
                                task.related_record_type ? 'has-badge' : ''
                              "
                            >
                              <p
                                class="
                                  task-action-type
                                  mb-0
                                  ml-3
                                  mr-xl-3
                                  pr-2 pr-md-0
                                "
                                v-html="
                                  task.description +
                                  '<small> on ' +
                                  formattedDate(task.due_date) +
                                  '</small>'
                                "
                              ></p>
                              <div
                                class="ml-auto task-type-header-container"
                                v-if="task.related_record_type"
                              >
                                <div
                                  :class="`task-type-header ellipsis ${task.className}`"
                                >
                                  {{ task.displayType }}
                                </div>
                              </div>
                              <div
                                class="ml-auto"
                                style="margin-right: -24px"
                                v-if="!task.related_record_type"
                              >
                                <i
                                  class="fas fa-2x text-dark"
                                  :class="
                                    task.type == 'SHARED_IN_FOLDER'
                                      ? 'fa-folder-open'
                                      : task.type.indexOf('REACTION') > -1
                                      ? 'fa-grin-stars'
                                      : 'fa-file-alt'
                                  "
                                ></i>
                              </div>
                            </div>
                            <i
                              class="fas fa-angle-down rotate-icon text-dark"
                              v-if="hasExpandFeature(task)"
                            ></i>
                          </button>
                        </h2>
                      </div>
                      <div
                        :id="`timeCollapseTask_${task.id}`"
                        class="collapse"
                        :class="[
                          task.read_at || task.assignee_id != user.id
                            ? ''
                            : 'taskAlert',
                        ]"
                        :aria-labelledby="`timeHeadingTask_${task.id}`"
                      >
                        <div class="card-body" v-if="task.messageBody">
                          <div class="task-title text-bold text-center">
                            <span v-if="!task.related_reply_id">
                              Subject: {{ task.related_record_name }}
                            </span>
                            <span v-if="task.related_reply_id">
                              Reply on task ({{ task.related_record_name }})
                            </span>
                          </div>
                          <br />
                          <div
                            class="card shadow-none p-2 ck ck-content"
                            v-html="
                              $options.filters.nl2br(
                                $options.filters.slash2hr(task.messageBody)
                              )
                            "
                          ></div>
                          <div
                            v-if="task.link"
                            class="
                              d-flex
                              flex-wrap
                              align-items-center
                              justify-content-center
                              py-4
                              px-2
                            "
                            style="
                              background-color: #e6e0e069;
                              border-radius: 4px;
                            "
                          >
                            <button
                              class="btn btn-black"
                              @click="navigate(task.link)"
                            >
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as dayjs from "dayjs";
import { orderBy } from "lodash";
import Swal from "sweetalert2";
import data from "emoji-mart-vue-fast/data/google.json";
import CONSTANT from "../views/tasks/constants";
import { EmojiIndex } from "emoji-mart-vue-fast";
let emojiIndex = new EmojiIndex(data);

export default {
  name: "timeline",
  props: ["userId", "maxItems"],
  computed: {
    filteredTimeline: function () {
      let filteredItems = this.timeLine;
      if (this.timeLine && this.timeLine.length && this.type != "-1") {
        if (this.type == "-2") {
          filteredItems = this.timeLine.filter(
            (item) =>
              item.type.indexOf("_TASK") > -1 ||
              item.type.indexOf("TAGGED_IN_REPLY") > -1
          );
        } else {
          filteredItems = this.timeLine.filter(
            (item) =>
              item.type == this.type || item.type == `${this.type}_UPDATE`
          );
        }
      }
      if (this.selectedTaskType != "-1") {
        filteredItems = filteredItems.filter(
          (item) => item.related_record_type == this.selectedTaskType
        );
      }
      if (this.maxItems) {
        filteredItems = filteredItems.slice(0, this.maxItems);
      }
      return filteredItems;
    },
    actionTypeRelatedToTask: function () {
      return (
        this.type == "-2" ||
        this.type.indexOf("_TASK") > -1 ||
        this.type.indexOf("TAGGED_IN_REPLY") > -1
      );
    },
    ...mapState({
      user: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
    }),
  },
  created() {
    this.getProviders().then(() => {
      this.getRelatedTask();
    });
  },
  methods: {
    checkSelectedTaskType() {
      if (
        this.type !== "-2" &&
        this.type.indexOf("_TASK") == -1 &&
        this.type.indexOf("TAGGED_IN_REPLY") == -1
      ) {
        this.selectedTaskType = "-1";
      }
    },
    setDisplayType(type) {
      this.listDisplay = type;
    },
    hasExpandFeature(task) {
      const types = [
        "ASSIGNED_TASK",
        "TAGGED_IN_TASK",
        "ASSIGNED_TASK_UPDATE",
        "TAGGED_IN_TASK_UPDATE",
        "ASSIGNED_TASK_REPLY",
        "ASSIGNED_TASK_REPLY_UPDATE",
        "TAGGED_IN_REPLY",
        "TAGGED_IN_REPLY_UPDATE",
        "AUTHOR_TASK_REPLY_UPDATE",
      ];
      return types.includes(task.type);
    },
    navigate(link) {
      window.open(link, "_blank");
    },
    getFolderNavigationUrl(activityItem) {
      var activityTypeObject = this.activitiyTypeMap[activityItem.type];
      if (
        activityItem.subscriber.isAdmin ||
        activityItem.subscriber.isManagingAdmin
      ) {
        return activityTypeObject.adminUrlNavigation;
      } else if (activityItem.subscriber.isPatient) {
        return activityTypeObject.patientUrlNavigation.replace(
          "id_replace",
          this.patient.id
        );
      } else if (activityItem.subscriber.isProvider) {
        const provider = this.providers.filter(
          (prov) => prov.user_id == activityItem.subscriber.id
        )[0];
        return activityTypeObject.providerUrlNavigation.replace(
          "id_replace",
          (provider || {}).id
        );
      }
      return "";
    },
    generateAcitivyDescriptionFromActivity(activityItem) {
      if (activityItem.publisher != null) {
        var activityTypeObject = this.activitiyTypeMap[activityItem.type];
        if (activityTypeObject != null) {
          if (activityItem.type == "TASK_REACTION") {
            return (
              " <span class='time-line-user-name'>" +
              activityItem.publisher.last +
              ", " +
              activityItem.publisher.first +
              "</span> " +
              activityTypeObject.preDisplayText +
              "<a target='_blank' href='" +
              activityTypeObject.urlNavigation +
              "/" +
              +activityItem.related_record_id +
              "'" +
              "> <span class='activityName'>" +
              (activityItem.related_record_name ||
                activityTypeObject.urlDsiplayText) +
              "</span> </a> " +
              activityTypeObject.postDisplayText +
              `<span>
                ${this.findEmoji(activityItem.reaction)}
              </span>)`
            );
          } else if (activityItem.type == "REPLY_REACTION") {
            return (
              " <span class='time-line-user-name'>" +
              activityItem.publisher.last +
              ", " +
              activityItem.publisher.first +
              "</span> " +
              activityTypeObject.preDisplayText +
              "<a target='_blank' href='" +
              activityTypeObject.urlNavigation +
              "/" +
              activityItem.related_record_id +
              (activityItem.related_reply_id
                ? `?reply_id=${activityItem.related_reply_id}`
                : "") +
              "'" +
              "> <span class='activityName'>" +
              activityItem.related_record_name +
              "</span> </a> " +
              activityTypeObject.postDisplayText +
              `<span>
                ${this.findEmoji(activityItem.reaction)}
              </span>)`
            );
          } else if (activityItem.type == "ASSIGNED_TASK_REPLY") {
            return (
              " <span class='time-line-user-name'>" +
              activityItem.publisher.last +
              ", " +
              activityItem.publisher.first +
              "</span> " +
              activityTypeObject.preDisplayText +
              "<a target='_blank' href='" +
              activityTypeObject.urlNavigation +
              "/" +
              activityItem.related_record_id +
              (activityItem.related_reply_id
                ? `?reply_id=${activityItem.related_reply_id}`
                : "") +
              "'" +
              "> <span class='activityName'>" +
              activityItem.related_record_name +
              "</span> </a> " +
              activityTypeObject.postDisplayText +
              " <span class='time-line-user-name'>" +
              activityItem.subscriber.last +
              ", " +
              activityItem.subscriber.first +
              (activityTypeObject.post2DisplayText || "") +
              "</span>"
            );
          } else if (activityItem.type == "TAGGED_IN_REPLY") {
            return (
              " <span class='time-line-user-name'>" +
              activityItem.subscriber.last +
              ", " +
              activityItem.subscriber.first +
              "</span> " +
              activityTypeObject.preDisplayText +
              "<a target='_blank' href='" +
              activityTypeObject.urlNavigation +
              "/" +
              activityItem.related_record_id +
              (activityItem.related_reply_id
                ? `?reply_id=${activityItem.related_reply_id}`
                : "") +
              "'" +
              "> <span class='activityName'>" +
              activityItem.related_record_name +
              "</span> </a> " +
              activityTypeObject.postDisplayText +
              " <span class='time-line-user-name'>" +
              activityItem.publisher.last +
              ", " +
              activityItem.publisher.first +
              "</span>"
            );
          } else if (activityItem.type == "AUTHOR_TASK_REPLY") {
            return (
              " <span class='time-line-user-name'>" +
              activityItem.publisher.last +
              ", " +
              activityItem.publisher.first +
              "</span> " +
              activityTypeObject.preDisplayText +
              "<a target='_blank' href='" +
              activityTypeObject.urlNavigation +
              "/" +
              activityItem.related_record_id +
              (activityItem.related_reply_id
                ? `?reply_id=${activityItem.related_reply_id}`
                : "") +
              "'" +
              "> <span class='activityName'>" +
              activityItem.related_record_name +
              "</span> </a> " +
              activityTypeObject.postDisplayText +
              " <span class='time-line-user-name'>" +
              activityItem.subscriber.last +
              ", " +
              activityItem.subscriber.first +
              "</span>"
            );
          } else if (activityItem.type == "AUTHOR_TASK_REPLY_UPDATE") {
            return (
              " <span class='time-line-user-name'>" +
              activityItem.publisher.last +
              ", " +
              activityItem.publisher.first +
              "</span> " +
              activityTypeObject.preDisplayText +
              "<a target='_blank' href='" +
              activityTypeObject.urlNavigation +
              "/" +
              activityItem.related_record_id +
              (activityItem.related_reply_id
                ? `?reply_id=${activityItem.related_reply_id}`
                : "") +
              "'" +
              "> <span class='activityName'>" +
              activityItem.related_record_name +
              "</span> </a> " +
              activityTypeObject.postDisplayText +
              " <span class='time-line-user-name'>" +
              activityItem.subscriber.last +
              ", " +
              activityItem.subscriber.first +
              "</span>"
            );
          } else if (activityItem.type == "ASSIGNED_TASK_REPLY_UPDATE") {
            return (
              " <span class='time-line-user-name'>" +
              activityItem.publisher.last +
              ", " +
              activityItem.publisher.first +
              "</span> " +
              activityTypeObject.preDisplayText +
              "<a target='_blank' href='" +
              activityTypeObject.urlNavigation +
              "/" +
              activityItem.related_record_id +
              (activityItem.related_reply_id
                ? `?reply_id=${activityItem.related_reply_id}`
                : "") +
              "'" +
              "> <span class='activityName'>" +
              activityItem.related_record_name +
              "</span> </a> " +
              activityTypeObject.postDisplayText +
              " <span class='time-line-user-name'>" +
              activityItem.subscriber.last +
              ", " +
              activityItem.subscriber.first +
              (activityTypeObject.post2DisplayText || "") +
              "</span>"
            );
          } else {
            return (
              " <span class='time-line-user-name'>" +
              (activityItem.type == "TAGGED_IN_TASK" ||
              activityItem.type == "TAGGED_IN_TASK_UPDATE" ||
              activityItem.type == "TAGGED_IN_TASK_REPLY"
                ? activityItem.subscriber
                : activityItem.publisher
              ).last +
              ", " +
              (activityItem.type == "TAGGED_IN_TASK" ||
              activityItem.type == "TAGGED_IN_TASK_UPDATE" ||
              activityItem.type == "TAGGED_IN_TASK_REPLY"
                ? activityItem.subscriber
                : activityItem.publisher
              ).first +
              "</span> " +
              activityTypeObject.preDisplayText +
              "<a target='_blank' href='" +
              (activityItem.type == "SHARED_IN_FOLDER" ||
              activityItem.type == "SHARED_IN_DOCUMENT"
                ? this.getFolderNavigationUrl(activityItem)
                : activityTypeObject.urlNavigation +
                  "/" +
                  activityItem.related_record_id) +
              (activityItem.related_reply_id
                ? `?reply_id=${activityItem.related_reply_id}`
                : "") +
              "'" +
              "> <span class='activityName'>" +
              (activityItem.related_record_name ||
                activityTypeObject.urlDsiplayText) +
              "</span> </a> " +
              activityTypeObject.postDisplayText +
              " <span class='time-line-user-name'>" +
              (activityItem.subscriber != null
                ? (activityItem.type == "TAGGED_IN_TASK" ||
                  activityItem.type == "TAGGED_IN_TASK_UPDATE"
                    ? activityItem.publisher
                    : activityItem.subscriber
                  ).last +
                  ", " +
                  (activityItem.type == "TAGGED_IN_TASK" ||
                  activityItem.type == "TAGGED_IN_TASK_UPDATE"
                    ? activityItem.publisher
                    : activityItem.subscriber
                  ).first
                : "") +
              (activityTypeObject.post2DisplayText || "") +
              "</span>"
            );
          }
        }
      } else {
        return "N/A";
      }
    },
    generateActivityLink(activityItem) {
      if (activityItem.publisher != null) {
        var activityTypeObject = this.activitiyTypeMap[activityItem.type];
        if (activityTypeObject != null) {
          return activityItem.type == "SHARED_IN_FOLDER" ||
            activityItem.type == "SHARED_IN_DOCUMENT"
            ? this.getFolderNavigationUrl(activityItem)
            : activityTypeObject.urlNavigation +
                "/" +
                +activityItem.related_record_id +
                (activityItem.related_reply_id
                  ? `?reply_id=${activityItem.related_reply_id}`
                  : "");
        }
        return undefined;
      } else {
        return undefined;
      }
    },
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat).format("YYYY-MM-DD (hh:mm A)");
      } else {
        return "";
      }
    },
    getRelatedTask: async function () {
      try {
        this.loading = true;
        const extra_params = this.maxItems ? `&size=${this.maxItems}` : "";
        const res = await this.$http.get(
          `basic-timeline?user_id=` + this.userId + extra_params
        );
        res.data.data.filter((item) => {
          this.timeLine.push({
            id: item.id,
            description: this.generateAcitivyDescriptionFromActivity(item),
            due_date: item.created_at,
            type: item.type,
            publisher: item.publisher,
            subscriber: item.subscriber,
            messageBody: item.description,
            link: this.generateActivityLink(item),
            related_record_name: item.related_record_name,
            related_reply_id: item.related_reply_id,
            related_record_type: item.related_record_type,
            displayType: item.related_record_type
              ? this.getTypeName(item.related_record_type)
              : null,
            className: item.related_record_type
              ? this.getTaskColor(item.related_record_type)
              : null,
          });
        });
        this.timeLine = orderBy(this.timeLine, ["due_date"], ["desc"]);
        this.loading = false;
      } catch (err) {
        Swal.fire({
          title: "Error",
          text: "Error on getting the data",
          icon: "error",
        });
        this.timeLine = [];
        this.loading = false;
      }
    },
    getTypeName(type) {
      return (this.taskTypes.filter((item) => item.value == type)[0] || {})
        .label;
    },
    getTaskColor(type) {
      let className = "";
      switch (type) {
        case "1":
          className = "btn-secondary";
          break;
        case "2":
          className = "btn-info";
          break;
        case "3":
          className = "btn-secondary";
          break;
        case "4":
          className = "btn-deep-red";
          break;
        case "5":
          className = "btn-info";
          break;
        case "6":
          className = "btn-info";
          break;
        case "7":
          className = "btn-secondary";
          break;
        case "8":
          className = "btn-info";
          break;
        case "9":
          className = "btn-7ac98b";
          break;
        case "10":
          className = "btn-info";
          break;
        case "11":
          className = "btn-deep-red";
          break;
        case "12":
          className = "btn-deep-warning";
          break;
        case "13":
          className = "btn-warning";
          break;
        case "14":
          className = "btn-success";
          break;
        case "15":
          className = "btn-deep-success";
          break;
        case "16":
          className = "btn-danger";
          break;

        case "17":
          className = "btn-danger";
          break;
        case "18":
          className = "btn-deep-red";
          break;
        case "19":
          className = "btn-info";
          break;
        case "20":
          className = "btn-7ac98b";
          break;
        case "21":
          className = "btn-639db9";
          break;
        case "22":
          className = "btn-a84bc6";
          break;
        case "23":
          className = "btn-e27c1d";
          break;
        case "24":
          className = "btn-e0511d";
          break;
        case "25":
          className = "btn-dd1c59";
          break;
        case "26":
          className = "btn-a31cdd";
          break;
        case "27":
          className = "btn-0859d3";
          break;
        case "28":
          className = "btn-157477";
          break;
        case "29":
          className = "btn-1a3f3f";
          break;
        case "30":
          className = "btn-0d62a8";
          break;
        case "39":
          className = "btn-dark";
          break;
        case "40":
          className = "btn-deep-red";
          break;
        default:
          className = "btn-danger";
          break;
      }
      return className;
    },
    getProviders: function () {
      const vm = this;
      return new Promise(function (resolve) {
        vm.$store
          .dispatch("providers/getProvidersUsersIds", { id: true, local: true })
          .then((providers) => {
            vm.providers = providers;
            resolve();
          });
      });
    },
    findEmoji: function (emoji) {
      return this.emojiIndex.findEmoji(emoji)?.native;
    },
  },
  data() {
    return {
      loading: true,
      timeLine: [],
      providers: [],
      listDisplay: this.maxItems ? "table" : "timeline",
      type: "-1",
      selectedTaskType: "-1",
      activitiyTypeMap: {
        ASSIGNED_TASK: {
          preDisplayText: "assigned task (",
          urlDsiplayText: "task",
          postDisplayText: ") to",
          urlNavigation: "/tasks/update",
        },
        ASSIGNED_TASK_UPDATE: {
          preDisplayText: "assigned task (",
          urlDsiplayText: "task",
          postDisplayText: ") <b class='text-danger'>[Modified]</b> to",
          urlNavigation: "/tasks/update",
        },
        ASSIGNED_TASK_REPLY: {
          preDisplayText: "replied to task (",
          postDisplayText: ") assigned to",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        ASSIGNED_TASK_REPLY_UPDATE: {
          preDisplayText: "modified a reply on task (",
          postDisplayText: ") assigned to",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        TAGGED_IN_REPLY: {
          preDisplayText: "has been tagged in a reply for task (",
          postDisplayText: ") by",
          urlNavigation: "/tasks/update",
        },
        AUTHOR_TASK_REPLY: {
          preDisplayText: "replied on a task (",
          postDisplayText: ") created by",
          urlNavigation: "/tasks/update",
        },
        AUTHOR_TASK_REPLY_UPDATE: {
          preDisplayText: "modified a reply on a task (",
          postDisplayText: "). task created by",
          urlNavigation: "/tasks/update",
        },
        TAGGED_IN_TASK: {
          preDisplayText: "has been tagged in task (",
          postDisplayText: ") by",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        TAGGED_IN_TASK_UPDATE: {
          preDisplayText: "has been tagged in task (",
          postDisplayText: ") <b class='text-danger'>[Modified]</b> by",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        TAGGED_IN_TASK_REPLY: {
          preDisplayText: "replied to task (",
          postDisplayText: ") where",
          post2DisplayText: " tagged in",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        SHARED_IN_FOLDER: {
          preDisplayText: "shared the folder (",
          postDisplayText: ") with",
          urlDsiplayText: "folder",
          adminUrlNavigation: "/admin/documents",
          patientUrlNavigation: "/clients/id_replace/documents",
          providerUrlNavigation: "/providers/id_replace/documents",
        },
        SHARED_IN_DOCUMENT: {
          preDisplayText: "shared the file (",
          postDisplayText: ") with",
          urlDsiplayText: "file",
          adminUrlNavigation: "/admin/documents",
          patientUrlNavigation: "/clients/id_replace/documents",
          providerUrlNavigation: "/providers/id_replace/documents",
        },
        TASK_REACTION: {
          preDisplayText: "reacted to task (",
          postDisplayText: ") with (",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        REPLY_REACTION: {
          preDisplayText: "reacted to reply on task (",
          postDisplayText: ") with (",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
      },
      actionTypes: [
        { value: "-1", label: "Select Action Type" },
        { value: "-2", label: "Task Timeline" },
        { value: "ASSIGNED_TASK", label: "Assigned Task" },
        { value: "ASSIGNED_TASK_UPDATE", label: "Assigned Task Update" },
        { value: "TAGGED_IN_TASK", label: "Tagged In Task" },
        { value: "TAGGED_IN_TASK_UPDATE", label: "Tagged In Task Update" },
        { value: "ASSIGNED_TASK_REPLY", label: "Reply On Assigned Task" },
        {
          value: "ASSIGNED_TASK_REPLY_UPDATE",
          label: "Reply Update On Assigned Task",
        },
        { value: "TAGGED_IN_REPLY", label: "Tagged In Reply" },
        { value: "AUTHOR_TASK_REPLY", label: "Reply On Created Task" },
        {
          value: "AUTHOR_TASK_REPLY_UPDATE",
          label: "Reply Update On Created Task",
        },
        { value: "TAGGED_IN_TASK_REPLY", label: "Reply On Tagged In Task" },
        { value: "TASK_REACTION", label: "Reaction On Task" },
        { value: "REPLY_REACTION", label: "Reaction On Reply" },
        { value: "SHARED_IN_FOLDER", label: "Folder Share" },
        { value: "SHARED_IN_DOCUMENT", label: "File Share" },
      ],
      taskTypes: [
        { value: "-1", label: "Select Task Type" },
        ...CONSTANT.TASK_TYPES.sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
      ],
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      timelineBarImg: require("@/assets/img/timeline-long.svg"),
      timelineImgBorder: require("@/assets/img/timeline-profile-container.svg"),
      emojiIndex: emojiIndex,
    };
  },
};
</script>

<style scoped lang="scss">
.sub-timeline-container {
  .cursor-default {
    cursor: default !important;
  }

  .tooltip-info {
    position: absolute;
    right: 15px;
    top: -17px;
  }

  .task-type-header {
    padding: 2px 12px;
    border-radius: 8px;
    pointer-events: none;
    font-size: 14px;
    max-width: 350px;
  }

  .task-type-header-container-table {
    display: inline-flex;
    align-items: center;
  }
  .task-type-header-table {
    padding: 2px 12px;
    border-radius: 8px;
    pointer-events: none;
    font-size: 14px;
    max-width: 350px;
  }
  @media (max-width: 1024px) {
    .heading-task-content {
      &.has-badge {
        margin-top: 32px;
      }
    }
    .task-type-header-container {
      position: absolute;
      max-width: calc(100% - 48px);
      right: 30px;
      top: 13px;
    }
  }
  .table-avatar-task-img {
    width: 45px;
    min-width: 45px;
    height: 45px;
  }
  .task-accordion {
    .avatar-task-img {
      width: 35px;
      min-width: 35px;
      height: 35px;
    }
    .taskAlert {
      border-left: 4px inset var(--theme-color);
      border-bottom: 0;
    }
    .rotate-icon {
      position: absolute;
      right: 12px;
      top: 13px;
      transition: all 0.3s ease-in-out;
    }
    button[aria-expanded="true"] {
      .rotate-icon {
        transform: rotate(180deg);
      }
    }
    .status-select .form-control {
      width: auto;
    }
  }
  .btn-outline-header-blue {
    &,
    &:focus {
      color: #062157;
      border-color: #062157;
      background-color: white;
    }

    &:hover {
      color: #0e43ad;
      border-color: #0e43ad;
      background-color: white;
    }
  }

  .btn-header-blue {
    &,
    &:hover,
    &:focus {
      color: white;
      border-color: #222222;
      background-color: #222222;
    }
  }
}
</style>
<style lang="scss">
.sub-timeline-container {
  .task-action-type {
    a {
      word-break: break-all;
    }
  }

  .timeline-task {
    .taskAlert {
      border-left: 4px inset var(--theme-color);
    }

    .timeline-dashboard-container {
      position: relative;
      padding-left: 50px;
    }

    .timeline-bar-container {
      .timeline-subject {
        .timeline-user-name {
          background-color: #0000000f;
          border-radius: 99px;
          padding: 2px 8px;
          font-weight: normal;
        }

        .task-emoji {
          border-radius: 6px;
          border: 1px solid #d2ddec;
          padding: 2px 4px;
          display: inline-block;
        }
      }
    }

    .timeline-bar {
      width: 23px;
      min-width: 23px;
      background-repeat: no-repeat;
      background-position: bottom;
      position: absolute;
      top: 6px;
      bottom: 40px;
      left: 27px;
    }

    .image-info {
      position: absolute;
      left: -51.5px;
      top: -16px;
      width: 60px;
    }

    .image-border {
      width: 100%;
    }

    .avatar-task-img {
      position: absolute;
      top: 26px;
      left: 2.5px;
      width: 55px;
      min-width: 55px;
      height: 55px;
    }

    .timeline-content {
      position: relative;
      width: 100%;
      .title {
        font-weight: 500;
        font-size: 32px;
      }

      .item-content {
        margin-top: 32px;
      }

      .timeline-date-info {
        background-color: rgba(37, 37, 37, 0.4);
        color: #ffffff;
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        padding: 12px 24px;

        .user-name {
          font-size: 18px;
          font-weight: 500;
        }

        .date-time {
          font-size: 16px;
          font-weight: normal;
          opacity: 0.72;
        }
      }
      .timeline-subject {
        background-color: #ffffff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 32px;
        border-bottom-left-radius: 32px;
        padding: 12px 0;
        margin-top: -4px;
      }
    }
  }
}
</style>
