var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-timeline-container"},[(_vm.loading)?_c('div',[_c('alert',{staticClass:"my-2 light-shadow"})],1):_vm._e(),(!_vm.loading && _vm.timeLine && _vm.timeLine.length && !_vm.maxItems)?_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"col-9 pl-0 pr-3 col-sm-6 col-md-4 mb-2 filter-items"},[_c('select-control',{attrs:{"options":_vm.actionTypes,"type":"vertical","wrapClass":"mb-0","inputClass":"light-shadow"},on:{"input":function($event){return _vm.checkSelectedTaskType()}},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('div',{staticClass:"col-9 pl-0 pr-3 col-sm-6 col-md-4 mb-2 filter-items"},[_c('select-control',{attrs:{"disabled":!_vm.actionTypeRelatedToTask,"options":_vm.taskTypes,"type":"vertical","wrapClass":"mb-0","inputClass":"light-shadow"},model:{value:(_vm.selectedTaskType),callback:function ($$v) {_vm.selectedTaskType=$$v},expression:"selectedTaskType"}}),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Available for actions related to tasks'),expression:"'Available for actions related to tasks'",modifiers:{"top":true}}],staticClass:"fa fa-info-circle ml-1 tooltip-info"})],1),_c('div',{staticClass:"d-flex mb-4 m-md-2 mb-md-3 ml-auto ml-md-auto align-items-end justify-content-end"},[_c('button',{class:`btn btn-md-sm ${
          _vm.listDisplay == 'timeline'
            ? 'btn-header-blue'
            : 'btn-outline-header-blue'
        }`,staticStyle:{"border-bottom-right-radius":"0","border-top-right-radius":"0"},on:{"click":function($event){return _vm.setDisplayType('timeline')}}},[_c('i',{staticClass:"fa-solid fa-timeline"})]),_c('button',{class:`btn btn-md-sm ${
          _vm.listDisplay == 'list'
            ? 'btn-header-blue'
            : 'btn-outline-header-blue'
        }`,staticStyle:{"border-radius":"0"},on:{"click":function($event){return _vm.setDisplayType('list')}}},[_c('i',{staticClass:"fas fa-list"})]),_c('button',{class:`btn btn-md-sm ${
          _vm.listDisplay == 'table'
            ? 'btn-header-blue'
            : 'btn-outline-header-blue'
        }`,staticStyle:{"border-bottom-left-radius":"0","border-top-left-radius":"0"},on:{"click":function($event){return _vm.setDisplayType('table')}}},[_c('i',{staticClass:"fas fa-th"})])])]):_vm._e(),(!_vm.loading && _vm.filteredTimeline && _vm.filteredTimeline.length == 0)?_c('alert',{staticClass:"my-2 light-shadow"},[_vm._v("No Items")]):_vm._e(),(!_vm.loading && _vm.filteredTimeline && _vm.filteredTimeline.length > 0)?_c('div',{staticClass:"mb-0 overflow-auto border-0 p-2 p-lg-0",class:[
      this.listDisplay == 'timeline'
        ? ''
        : 'card shadow-card mobile-tablet-no-card',
    ]},[_c('div',{staticClass:"mb-0 table-container"},[_c('table',{directives:[{name:"show",rawName:"v-show",value:(_vm.listDisplay == 'table'),expression:"listDisplay == 'table'"}],staticClass:"table table-sm table-striped mb-0 responsive-mobile-tablet-table"},[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"timeline-date"},[_vm._v("Date")]),_c('th',[_vm._v("Activity")]),(!_vm.maxItems)?_c('th',[_vm._v("Type")]):_vm._e()])]),_c('tbody',_vm._l((_vm.filteredTimeline),function(task){return _c('tr',{key:task.id},[_c('td',{staticClass:"px-4 py-2"},[_c('img',{staticClass:"table-avatar-task-img rounded-circle",attrs:{"src":(task.type == 'TAGGED_IN_TASK' ||
                  task.type == 'TAGGED_IN_TASK_UPDATE' ||
                  task.type == 'TAGGED_IN_TASK_REPLY'
                    ? task.subscriber.profile_picture
                    : task.publisher.profile_picture) ||
                  _vm.profileImagePlaceholder}})]),_c('td',{staticClass:"timeline-date"},[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Date:")]),_vm._v(_vm._s(_vm.formattedDate(task.due_date))+" ")]),_c('td',{staticStyle:{"font-size":"14px"}},[_c('b',{staticClass:"mobile-tablet-only mr-2 pt-0"},[_vm._v("Activity:")]),_c('div',{staticStyle:{"display":"inline-block"},domProps:{"innerHTML":_vm._s(task.description)}})]),(!_vm.maxItems)?_c('td',[(task.related_record_type)?_c('div',{staticClass:"task-type-header-container-table"},[_c('b',{staticClass:"mobile-tablet-only mr-2 pt-0"},[_vm._v("Type:")]),_c('div',{class:`task-type-header-table ellipsis ${task.className}`},[_vm._v(" "+_vm._s(task.displayType)+" ")])]):_vm._e(),(!task.related_record_type)?_c('div',{staticClass:"task-type-header-container-table"},[_c('b',{staticClass:"mobile-tablet-only mr-2 pt-0"},[_vm._v("Type:")]),_c('i',{staticClass:"fas fa-2x text-dark",class:task.type == 'SHARED_IN_FOLDER'
                      ? 'fa-folder-open'
                      : task.type.indexOf('REACTION') > -1
                      ? 'fa-grin-stars'
                      : 'fa-file-alt'})]):_vm._e()]):_vm._e()])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.listDisplay == 'list'),expression:"listDisplay == 'list'"}],staticClass:"accordion task-accordion",attrs:{"id":"tasksAccordion"}},_vm._l((_vm.filteredTimeline),function(task,taskIndex){return _c('div',{key:task.id,staticClass:"card mb-0",class:`item-index_${taskIndex}`},[_c('div',{staticClass:"card-header p-0",staticStyle:{"height":"auto","min-height":"60px"},attrs:{"id":`headingTask_${task.id}`}},[_c('h2',{staticClass:"mb-0"},[_c('button',{staticClass:"btn btn-link text-left w-100 shadow-none",class:!_vm.hasExpandFeature(task) ? 'cursor-default' : '',attrs:{"type":"button","data-toggle":"collapse","data-target":_vm.hasExpandFeature(task) && `#collapseTask_${task.id}`,"aria-expanded":"false","aria-controls":_vm.hasExpandFeature(task) && `collapseTask_${task.id}`}},[_c('div',{staticClass:"d-flex heading-task-content text-dark align-items-start align-items-sm-center pr-4",class:task.related_record_type ? 'has-badge' : ''},[_c('img',{staticClass:"avatar-task-img rounded-circle",attrs:{"src":(task.type == 'TAGGED_IN_TASK' ||
                      task.type == 'TAGGED_IN_TASK_UPDATE' ||
                      task.type == 'TAGGED_IN_TASK_REPLY'
                        ? task.subscriber.profile_picture
                        : task.publisher.profile_picture) ||
                      _vm.profileImagePlaceholder}}),_c('p',{staticClass:"task-action-type mb-0 ml-3 mr-xl-3 pr-2 pr-md-0",domProps:{"innerHTML":_vm._s(
                      task.description +
                      '<small> on ' +
                      _vm.formattedDate(task.due_date) +
                      '</small>'
                    )}}),(task.related_record_type)?_c('div',{staticClass:"ml-auto task-type-header-container"},[_c('div',{class:`task-type-header ellipsis ${task.className}`},[_vm._v(" "+_vm._s(task.displayType)+" ")])]):_vm._e(),(!task.related_record_type)?_c('div',{staticClass:"ml-auto",staticStyle:{"margin-right":"-24px"}},[_c('i',{staticClass:"fas fa-2x text-dark",class:task.type == 'SHARED_IN_FOLDER'
                          ? 'fa-folder-open'
                          : task.type.indexOf('REACTION') > -1
                          ? 'fa-grin-stars'
                          : 'fa-file-alt'})]):_vm._e()]),(_vm.hasExpandFeature(task))?_c('i',{staticClass:"fas fa-angle-down rotate-icon text-dark"}):_vm._e()])])]),_c('div',{staticClass:"collapse",class:[
              task.read_at || task.assignee_id != _vm.user.id ? '' : 'taskAlert',
            ],attrs:{"id":`collapseTask_${task.id}`,"aria-labelledby":`headingTask_${task.id}`}},[(task.messageBody)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"task-title text-bold text-center"},[(!task.related_reply_id)?_c('span',[_vm._v(" Subject: "+_vm._s(task.related_record_name)+" ")]):_vm._e(),(task.related_reply_id)?_c('span',[_vm._v(" Reply on task ("+_vm._s(task.related_record_name)+") ")]):_vm._e()]),_c('br'),_c('div',{staticClass:"card shadow-none p-2 ck ck-content",domProps:{"innerHTML":_vm._s(
                  _vm.$options.filters.nl2br(
                    _vm.$options.filters.slash2hr(task.messageBody)
                  )
                )}}),(task.link)?_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-center py-4 px-2",staticStyle:{"background-color":"#e6e0e069","border-radius":"4px"}},[_c('button',{staticClass:"btn btn-black",on:{"click":function($event){return _vm.navigate(task.link)}}},[_vm._v(" View ")])]):_vm._e()]):_vm._e()])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.listDisplay == 'timeline'),expression:"listDisplay == 'timeline'"}],staticClass:"timeline-task pl-1"},[_c('div',{staticClass:"timeline-dashboard-container d-flex"},[_c('div',{staticClass:"timeline-bar",style:({ backgroundImage: `url(${_vm.timelineBarImg})` })}),_c('div',{staticClass:"timeline-content"},[_c('h1',{staticClass:"mb-0 pt-3"},[_vm._v("Timeline")]),_vm._l((_vm.filteredTimeline),function(task){return _c('div',{key:task.id,staticClass:"position-relative"},[_c('div',{staticClass:"image-info"},[_c('img',{staticClass:"image-border",attrs:{"src":_vm.timelineImgBorder}}),_c('img',{staticClass:"avatar-task-img rounded-circle",attrs:{"src":(task.type == 'TAGGED_IN_TASK' ||
                    task.type == 'TAGGED_IN_TASK_UPDATE' ||
                    task.type == 'TAGGED_IN_TASK_REPLY'
                      ? task.subscriber.profile_picture
                      : task.publisher.profile_picture) ||
                    _vm.profileImagePlaceholder}})]),_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"timeline-date-info"},[_c('p',{staticClass:"user-name mb-0"},[_vm._v(_vm._s(task.related_record_name))]),_c('p',{staticClass:"date-time mb-0"},[_vm._v(" "+_vm._s(_vm.formattedDate(task.due_date))+" ")])]),_c('div',{staticClass:"timeline-subject"},[_c('div',{staticClass:"accordion task-accordion",attrs:{"id":"timeTasksAccordion"}},[_c('div',{staticClass:"card-header p-0 border-0",staticStyle:{"height":"auto","min-height":"60px"},attrs:{"id":`timeHeadingTask_${task.id}`}},[_c('h2',{staticClass:"mb-0"},[_c('button',{staticClass:"btn btn-link text-left w-100 shadow-none position-relative",class:!_vm.hasExpandFeature(task) ? 'cursor-default' : '',attrs:{"type":"button","data-toggle":"collapse","data-target":_vm.hasExpandFeature(task) &&
                            `#timeCollapseTask_${task.id}`,"aria-expanded":"false","aria-controls":_vm.hasExpandFeature(task) &&
                            `timeCollapseTask_${task.id}`}},[_c('div',{staticClass:"d-flex heading-task-content text-dark align-items-start align-items-sm-center pr-4",class:task.related_record_type ? 'has-badge' : ''},[_c('p',{staticClass:"task-action-type mb-0 ml-3 mr-xl-3 pr-2 pr-md-0",domProps:{"innerHTML":_vm._s(
                                task.description +
                                '<small> on ' +
                                _vm.formattedDate(task.due_date) +
                                '</small>'
                              )}}),(task.related_record_type)?_c('div',{staticClass:"ml-auto task-type-header-container"},[_c('div',{class:`task-type-header ellipsis ${task.className}`},[_vm._v(" "+_vm._s(task.displayType)+" ")])]):_vm._e(),(!task.related_record_type)?_c('div',{staticClass:"ml-auto",staticStyle:{"margin-right":"-24px"}},[_c('i',{staticClass:"fas fa-2x text-dark",class:task.type == 'SHARED_IN_FOLDER'
                                    ? 'fa-folder-open'
                                    : task.type.indexOf('REACTION') > -1
                                    ? 'fa-grin-stars'
                                    : 'fa-file-alt'})]):_vm._e()]),(_vm.hasExpandFeature(task))?_c('i',{staticClass:"fas fa-angle-down rotate-icon text-dark"}):_vm._e()])])]),_c('div',{staticClass:"collapse",class:[
                        task.read_at || task.assignee_id != _vm.user.id
                          ? ''
                          : 'taskAlert',
                      ],attrs:{"id":`timeCollapseTask_${task.id}`,"aria-labelledby":`timeHeadingTask_${task.id}`}},[(task.messageBody)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"task-title text-bold text-center"},[(!task.related_reply_id)?_c('span',[_vm._v(" Subject: "+_vm._s(task.related_record_name)+" ")]):_vm._e(),(task.related_reply_id)?_c('span',[_vm._v(" Reply on task ("+_vm._s(task.related_record_name)+") ")]):_vm._e()]),_c('br'),_c('div',{staticClass:"card shadow-none p-2 ck ck-content",domProps:{"innerHTML":_vm._s(
                            _vm.$options.filters.nl2br(
                              _vm.$options.filters.slash2hr(task.messageBody)
                            )
                          )}}),(task.link)?_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-center py-4 px-2",staticStyle:{"background-color":"#e6e0e069","border-radius":"4px"}},[_c('button',{staticClass:"btn btn-black",on:{"click":function($event){return _vm.navigate(task.link)}}},[_vm._v(" View ")])]):_vm._e()]):_vm._e()])])])])])})],2)])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }