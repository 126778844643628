<template>
  <div class="h-100 timeline-bar-container">
    <div v-if="loading">
      <alert class="my-2" />
    </div>
    <alert
      class="my-2 light-shadow"
      v-if="!loading && filteredTimeline && filteredTimeline.length == 0"
      >No Items</alert
    >

    <div
      v-if="!loading && filteredTimeline && filteredTimeline.length > 0"
      class="timeline-dashboard-container d-flex"
    >
      <div
        class="timeline-bar"
        :style="{ backgroundImage: `url(${timelineBarImg})` }"
      ></div>
      <div class="timeline-content">
        <h1 class="title text-main">Timeline</h1>

        <div
          v-for="task in filteredTimeline"
          :key="task.id"
          class="position-relative"
        >
          <div class="image-info">
            <img :src="timelineImgBorder" class="image-border" />
            <img
              class="avatar-task-img rounded-circle"
              :src="
                (task.type == 'TAGGED_IN_TASK' ||
                task.type == 'TAGGED_IN_TASK_UPDATE' ||
                task.type == 'TAGGED_IN_TASK_REPLY'
                  ? task.subscriber.profile_picture
                  : task.publisher.profile_picture) || profileImagePlaceholder
              "
            />
          </div>
          <div class="item-content">
            <div class="timeline-date-info">
              <p class="user-name mb-0">{{ task.details.name }}</p>
              <p class="date-time mb-0">{{ formattedDate(task.due_date) }}</p>
            </div>
            <div class="timeline-subject">
              <div class="two-lines" v-html="task.details.description"></div>
            </div>
          </div>
        </div>
        <div
          class="see-all-timeline pointer-cursor d-flex justify-content-between"
          @click="navigateToTimeline"
        >
          <span>See all</span> <i class="fas fa-chevron-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as dayjs from "dayjs";
import { orderBy } from "lodash";
import Swal from "sweetalert2";
import data from "emoji-mart-vue-fast/data/google.json";
import CONSTANT from "../views/tasks/constants";
import { EmojiIndex } from "emoji-mart-vue-fast";
let emojiIndex = new EmojiIndex(data);

export default {
  name: "timeline-bar",
  props: ["userId", "maxItems"],
  computed: {
    filteredTimeline: function () {
      let filteredItems = this.timeLine;
      if (this.timeLine && this.timeLine.length && this.type != "-1") {
        if (this.type == "-2") {
          filteredItems = this.timeLine.filter(
            (item) =>
              item.type.indexOf("_TASK") > -1 ||
              item.type.indexOf("TAGGED_IN_REPLY") > -1
          );
        } else {
          filteredItems = this.timeLine.filter(
            (item) =>
              item.type == this.type || item.type == `${this.type}_UPDATE`
          );
        }
      }
      if (this.selectedTaskType != "-1") {
        filteredItems = filteredItems.filter(
          (item) => item.related_record_type == this.selectedTaskType
        );
      }
      if (this.maxItems) {
        filteredItems = filteredItems.slice(0, this.maxItems);
      }
      return filteredItems;
    },
    actionTypeRelatedToTask: function () {
      return (
        this.type == "-2" ||
        this.type.indexOf("_TASK") > -1 ||
        this.type.indexOf("TAGGED_IN_REPLY") > -1
      );
    },
    ...mapState({
      user: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
    }),
  },
  created() {
    this.getProviders().then(() => {
      this.getRelatedTask();
    });
  },
  methods: {
    navigateToTimeline: function () {
      this.$router.push({ path: "/timeline" });
    },
    checkSelectedTaskType() {
      if (
        this.type !== "-2" &&
        this.type.indexOf("_TASK") == -1 &&
        this.type.indexOf("TAGGED_IN_REPLY") == -1
      ) {
        this.selectedTaskType = "-1";
      }
    },
    getFolderNavigationUrl(activityItem) {
      var activityTypeObject = this.activitiyTypeMap[activityItem.type];
      if (
        activityItem.subscriber.isAdmin ||
        activityItem.subscriber.isManagingAdmin
      ) {
        return activityTypeObject.adminUrlNavigation;
      } else if (activityItem.subscriber.isPatient) {
        return activityTypeObject.patientUrlNavigation.replace(
          "id_replace",
          this.patient.id
        );
      } else if (activityItem.subscriber.isProvider) {
        const provider = this.providers.filter(
          (prov) => prov.user_id == activityItem.subscriber.id
        )[0];
        return activityTypeObject.providerUrlNavigation.replace(
          "id_replace",
          (provider || {}).id
        );
      }
      return "";
    },
    generateAcitivyDescriptionFromActivity(activityItem) {
      if (activityItem.publisher != null) {
        var activityTypeObject = this.activitiyTypeMap[activityItem.type];
        if (activityTypeObject != null) {
          if (activityItem.type == "TASK_REACTION") {
            return {
              name:
                activityItem.publisher.last +
                ", " +
                activityItem.publisher.first,
              description:
                activityTypeObject.preDisplayText +
                "<a target='_blank' href='" +
                activityTypeObject.urlNavigation +
                "/" +
                +activityItem.related_record_id +
                "'" +
                "> <span class='activityName'>" +
                (activityItem.related_record_name ||
                  activityTypeObject.urlDsiplayText) +
                "</span> </a> " +
                activityTypeObject.postDisplayText +
                `<span class='task-emoji'>
                ${this.findEmoji(activityItem.reaction)}
              </span>`,
            };
          } else if (activityItem.type == "REPLY_REACTION") {
            return {
              name:
                activityItem.publisher.last +
                ", " +
                activityItem.publisher.first,
              description:
                activityTypeObject.preDisplayText +
                "<a target='_blank' href='" +
                activityTypeObject.urlNavigation +
                "/" +
                activityItem.related_record_id +
                (activityItem.related_reply_id
                  ? `?reply_id=${activityItem.related_reply_id}`
                  : "") +
                "'" +
                "> <span class='activityName'>" +
                activityItem.related_record_name +
                "</span> </a> " +
                activityTypeObject.postDisplayText +
                `<span class='task-emoji'>
                ${this.findEmoji(activityItem.reaction)}
              </span>`,
            };
          } else if (activityItem.type == "ASSIGNED_TASK_REPLY") {
            return {
              name:
                activityItem.publisher.last +
                ", " +
                activityItem.publisher.first,
              description:
                activityTypeObject.preDisplayText +
                "<a target='_blank' href='" +
                activityTypeObject.urlNavigation +
                "/" +
                activityItem.related_record_id +
                (activityItem.related_reply_id
                  ? `?reply_id=${activityItem.related_reply_id}`
                  : "") +
                "'" +
                "> <span class='activityName'>" +
                activityItem.related_record_name +
                "</span> </a> " +
                activityTypeObject.postDisplayText +
                " <span class='timeline-user-name'>" +
                activityItem.subscriber.last +
                ", " +
                activityItem.subscriber.first +
                (activityTypeObject.post2DisplayText || "") +
                "</span>",
            };
          } else if (activityItem.type == "TAGGED_IN_REPLY") {
            return {
              name:
                activityItem.subscriber.last +
                ", " +
                activityItem.subscriber.first,
              description:
                activityTypeObject.preDisplayText +
                "<a target='_blank' href='" +
                activityTypeObject.urlNavigation +
                "/" +
                activityItem.related_record_id +
                (activityItem.related_reply_id
                  ? `?reply_id=${activityItem.related_reply_id}`
                  : "") +
                "'" +
                "> <span class='activityName'>" +
                activityItem.related_record_name +
                "</span> </a> " +
                activityTypeObject.postDisplayText +
                " <span class='timeline-user-name'>" +
                activityItem.publisher.last +
                ", " +
                activityItem.publisher.first +
                "</span>",
            };
          } else if (activityItem.type == "AUTHOR_TASK_REPLY") {
            return {
              name:
                activityItem.publisher.last +
                ", " +
                activityItem.publisher.first,
              description:
                activityTypeObject.preDisplayText +
                "<a target='_blank' href='" +
                activityTypeObject.urlNavigation +
                "/" +
                activityItem.related_record_id +
                (activityItem.related_reply_id
                  ? `?reply_id=${activityItem.related_reply_id}`
                  : "") +
                "'" +
                "> <span class='activityName'>" +
                activityItem.related_record_name +
                "</span> </a> " +
                activityTypeObject.postDisplayText +
                " <span class='timeline-user-name'>" +
                activityItem.subscriber.last +
                ", " +
                activityItem.subscriber.first +
                "</span>",
            };
          } else if (activityItem.type == "AUTHOR_TASK_REPLY_UPDATE") {
            return {
              name:
                activityItem.publisher.last +
                ", " +
                activityItem.publisher.first,
              description:
                activityTypeObject.preDisplayText +
                "<a target='_blank' href='" +
                activityTypeObject.urlNavigation +
                "/" +
                activityItem.related_record_id +
                (activityItem.related_reply_id
                  ? `?reply_id=${activityItem.related_reply_id}`
                  : "") +
                "'" +
                "> <span class='activityName'>" +
                activityItem.related_record_name +
                "</span> </a> " +
                activityTypeObject.postDisplayText +
                " <span class='timeline-user-name'>" +
                activityItem.subscriber.last +
                ", " +
                activityItem.subscriber.first +
                "</span>",
            };
          } else if (activityItem.type == "ASSIGNED_TASK_REPLY_UPDATE") {
            return {
              name:
                activityItem.publisher.last +
                ", " +
                activityItem.publisher.first,
              description:
                activityTypeObject.preDisplayText +
                "<a target='_blank' href='" +
                activityTypeObject.urlNavigation +
                "/" +
                activityItem.related_record_id +
                (activityItem.related_reply_id
                  ? `?reply_id=${activityItem.related_reply_id}`
                  : "") +
                "'" +
                "> <span class='activityName'>" +
                activityItem.related_record_name +
                "</span> </a> " +
                activityTypeObject.postDisplayText +
                " <span class='timeline-user-name'>" +
                activityItem.subscriber.last +
                ", " +
                activityItem.subscriber.first +
                (activityTypeObject.post2DisplayText || "") +
                "</span>",
            };
          } else {
            return {
              name:
                (activityItem.type == "TAGGED_IN_TASK" ||
                activityItem.type == "TAGGED_IN_TASK_UPDATE" ||
                activityItem.type == "TAGGED_IN_TASK_REPLY"
                  ? activityItem.subscriber
                  : activityItem.publisher
                ).last +
                ", " +
                (activityItem.type == "TAGGED_IN_TASK" ||
                activityItem.type == "TAGGED_IN_TASK_UPDATE" ||
                activityItem.type == "TAGGED_IN_TASK_REPLY"
                  ? activityItem.subscriber
                  : activityItem.publisher
                ).first,
              description:
                activityTypeObject.preDisplayText +
                "<a target='_blank' href='" +
                (activityItem.type == "SHARED_IN_FOLDER" ||
                activityItem.type == "SHARED_IN_DOCUMENT"
                  ? this.getFolderNavigationUrl(activityItem)
                  : activityTypeObject.urlNavigation +
                    "/" +
                    activityItem.related_record_id) +
                (activityItem.related_reply_id
                  ? `?reply_id=${activityItem.related_reply_id}`
                  : "") +
                "'" +
                "> <span class='activityName'>" +
                (activityItem.related_record_name ||
                  activityTypeObject.urlDsiplayText) +
                "</span> </a> " +
                activityTypeObject.postDisplayText +
                " <span class='timeline-user-name'>" +
                (activityItem.subscriber != null
                  ? (activityItem.type == "TAGGED_IN_TASK" ||
                    activityItem.type == "TAGGED_IN_TASK_UPDATE"
                      ? activityItem.publisher
                      : activityItem.subscriber
                    ).last +
                    ", " +
                    (activityItem.type == "TAGGED_IN_TASK" ||
                    activityItem.type == "TAGGED_IN_TASK_UPDATE"
                      ? activityItem.publisher
                      : activityItem.subscriber
                    ).first
                  : "") +
                (activityTypeObject.post2DisplayText || "") +
                "</span>",
            };
          }
        }
      } else {
        return {
          name: "N/A",
          description: "N/A",
        };
      }
    },
    generateActivityLink(activityItem) {
      if (activityItem.publisher != null) {
        var activityTypeObject = this.activitiyTypeMap[activityItem.type];
        if (activityTypeObject != null) {
          return activityItem.type == "SHARED_IN_FOLDER" ||
            activityItem.type == "SHARED_IN_DOCUMENT"
            ? this.getFolderNavigationUrl(activityItem)
            : activityTypeObject.urlNavigation +
                "/" +
                +activityItem.related_record_id +
                (activityItem.related_reply_id
                  ? `?reply_id=${activityItem.related_reply_id}`
                  : "");
        }
        return undefined;
      } else {
        return undefined;
      }
    },
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat).format("MMMM DD・hh:mm A");
      } else {
        return "";
      }
    },
    getRelatedTask: async function () {
      try {
        this.loading = true;
        const extra_params = this.maxItems ? `&size=${this.maxItems}` : "";
        const res = await this.$http.get(
          `basic-timeline?user_id=` + this.userId + extra_params
        );
        res.data.data.filter((item) => {
          this.timeLine.push({
            id: item.id,
            details: this.generateAcitivyDescriptionFromActivity(item),
            due_date: item.created_at,
            type: item.type,
            publisher: item.publisher,
            subscriber: item.subscriber,
            messageBody: item.description,
            link: this.generateActivityLink(item),
            related_record_name: item.related_record_name,
            related_reply_id: item.related_reply_id,
            related_record_type: item.related_record_type,
            displayType: item.related_record_type
              ? this.getTypeName(item.related_record_type)
              : null,
            className: item.related_record_type
              ? this.getTaskColor(item.related_record_type)
              : null,
          });
        });
        this.timeLine = orderBy(this.timeLine, ["due_date"], ["desc"]);
        this.loading = false;
      } catch (err) {
        Swal.fire({
          title: "Error",
          text: "Error on getting the data",
          icon: "error",
        });
        this.timeLine = [];
        this.loading = false;
      }
    },
    getTypeName(type) {
      return (this.taskTypes.filter((item) => item.value == type)[0] || {})
        .label;
    },
    getTaskColor(type) {
      let className = "";
      switch (type) {
        case "1":
          className = "btn-secondary";
          break;
        case "2":
          className = "btn-info";
          break;
        case "3":
          className = "btn-secondary";
          break;
        case "4":
          className = "btn-deep-red";
          break;
        case "5":
          className = "btn-info";
          break;
        case "6":
          className = "btn-info";
          break;
        case "7":
          className = "btn-secondary";
          break;
        case "8":
          className = "btn-info";
          break;
        case "9":
          className = "btn-7ac98b";
          break;
        case "10":
          className = "btn-info";
          break;
        case "11":
          className = "btn-deep-red";
          break;
        case "12":
          className = "btn-deep-warning";
          break;
        case "13":
          className = "btn-warning";
          break;
        case "14":
          className = "btn-success";
          break;
        case "15":
          className = "btn-deep-success";
          break;
        case "16":
          className = "btn-danger";
          break;

        case "17":
          className = "btn-danger";
          break;
        case "18":
          className = "btn-deep-red";
          break;
        case "19":
          className = "btn-info";
          break;
        case "20":
          className = "btn-7ac98b";
          break;
        case "21":
          className = "btn-639db9";
          break;
        case "22":
          className = "btn-a84bc6";
          break;
        case "23":
          className = "btn-e27c1d";
          break;
        case "24":
          className = "btn-e0511d";
          break;
        case "25":
          className = "btn-dd1c59";
          break;
        case "26":
          className = "btn-a31cdd";
          break;
        case "27":
          className = "btn-0859d3";
          break;
        case "28":
          className = "btn-157477";
          break;
        case "29":
          className = "btn-1a3f3f";
          break;
        case "30":
          className = "btn-0d62a8";
          break;
        case "39":
          className = "btn-dark";
          break;
        case "40":
          className = "btn-deep-red";
          break;
        default:
          className = "btn-danger";
          break;
      }
      return className;
    },
    getProviders: function () {
      const vm = this;
      return new Promise(function (resolve) {
        vm.$store
          .dispatch("providers/getProvidersUsersIds", { id: true, local: true })
          .then((providers) => {
            vm.providers = providers;
            resolve();
          });
      });
    },
    findEmoji: function (emoji) {
      return this.emojiIndex.findEmoji(emoji)?.native;
    },
  },
  data() {
    return {
      loading: true,
      timelineBarImg: require("@/assets/img/timeline.svg"),
      timelineImgBorder: require("@/assets/img/timeline-profile-container.svg"),
      timeLine: [],
      providers: [],
      listDisplay: !this.maxItems,
      type: "-1",
      selectedTaskType: "-1",
      activitiyTypeMap: {
        ASSIGNED_TASK: {
          preDisplayText: "Assigned task (",
          urlDsiplayText: "task",
          postDisplayText: ") to",
          urlNavigation: "/tasks/update",
        },
        ASSIGNED_TASK_UPDATE: {
          preDisplayText: "Assigned updated task (",
          urlDsiplayText: "task",
          postDisplayText: ") to",
          urlNavigation: "/tasks/update",
        },
        ASSIGNED_TASK_REPLY: {
          preDisplayText: "Replied to task (",
          postDisplayText: ") assigned to",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        ASSIGNED_TASK_REPLY_UPDATE: {
          preDisplayText: "Modified a reply on task (",
          postDisplayText: ") assigned to",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        TAGGED_IN_REPLY: {
          preDisplayText: "Has been tagged in a reply for task (",
          postDisplayText: ") by",
          urlNavigation: "/tasks/update",
        },
        AUTHOR_TASK_REPLY: {
          preDisplayText: "Replied on a task (",
          postDisplayText: ") created by",
          urlNavigation: "/tasks/update",
        },
        AUTHOR_TASK_REPLY_UPDATE: {
          preDisplayText: "Modified a reply on a task (",
          postDisplayText: "). task created by",
          urlNavigation: "/tasks/update",
        },
        TAGGED_IN_TASK: {
          preDisplayText: "Has been tagged in task (",
          postDisplayText: ") by",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        TAGGED_IN_TASK_UPDATE: {
          preDisplayText: "Has been tagged in updated task (",
          postDisplayText: ") by",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        TAGGED_IN_TASK_REPLY: {
          preDisplayText: "Replied to task (",
          postDisplayText: ") where",
          post2DisplayText: " tagged in",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        SHARED_IN_FOLDER: {
          preDisplayText: "Shared the folder (",
          postDisplayText: ") with",
          urlDsiplayText: "folder",
          adminUrlNavigation: "/admin/documents",
          patientUrlNavigation: "/clients/id_replace/documents",
          providerUrlNavigation: "/providers/id_replace/documents",
        },
        SHARED_IN_DOCUMENT: {
          preDisplayText: "Shared the file (",
          postDisplayText: ") with",
          urlDsiplayText: "file",
          adminUrlNavigation: "/admin/documents",
          patientUrlNavigation: "/clients/id_replace/documents",
          providerUrlNavigation: "/providers/id_replace/documents",
        },
        TASK_REACTION: {
          preDisplayText: "Reacted to task (",
          postDisplayText: ") with ",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
        REPLY_REACTION: {
          preDisplayText: "Reacted to a reply on task (",
          postDisplayText: ") with ",
          urlDsiplayText: "task",
          urlNavigation: "/tasks/update",
        },
      },
      actionTypes: [
        { value: "-1", label: "Select Action Type" },
        { value: "-2", label: "Task Timeline" },
        { value: "ASSIGNED_TASK", label: "Assigned Task" },
        { value: "ASSIGNED_TASK_UPDATE", label: "Assigned Task Update" },
        { value: "TAGGED_IN_TASK", label: "Tagged In Task" },
        { value: "TAGGED_IN_TASK_UPDATE", label: "Tagged In Task Update" },
        { value: "ASSIGNED_TASK_REPLY", label: "Reply On Assigned Task" },
        {
          value: "ASSIGNED_TASK_REPLY_UPDATE",
          label: "Reply Update On Assigned Task",
        },
        { value: "TAGGED_IN_REPLY", label: "Tagged In Reply" },
        { value: "AUTHOR_TASK_REPLY", label: "Reply On Created Task" },
        {
          value: "AUTHOR_TASK_REPLY_UPDATE",
          label: "Reply Update On Created Task",
        },
        { value: "TAGGED_IN_TASK_REPLY", label: "Reply On Tagged In Task" },
        { value: "TASK_REACTION", label: "Reaction On Task" },
        { value: "REPLY_REACTION", label: "Reaction On Reply" },
        { value: "SHARED_IN_FOLDER", label: "Folder Share" },
        { value: "SHARED_IN_DOCUMENT", label: "File Share" },
      ],
      taskTypes: [
        { value: "-1", label: "Select Task Type" },
        ...CONSTANT.TASK_TYPES.sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
      ],
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      emojiIndex: emojiIndex,
    };
  },
};
</script>

<style scoped lang="scss">
.timeline-dashboard-container {
  height: 100%;
  padding-left: 50px;
  padding-bottom: 21px;

  @media (max-width: 990px) {
    padding-left: 0;
  }

  .timeline-bar {
    width: 23px;
    min-width: 23px;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .image-info {
    position: absolute;
    left: -51.5px;
    top: -16px;
    width: 60px;
  }

  .image-border {
    width: 100%;
  }

  .avatar-task-img {
    position: absolute;
    top: 26px;
    left: 2.5px;
    width: 55px;
    min-width: 55px;
    height: 55px;
  }

  .timeline-content {
    margin-bottom: 54px;
    position: relative;
    width: 100%;
    .title {
      font-weight: 500;
      font-size: 32px;
    }

    .item-content {
      margin-top: 32px;
    }

    .timeline-date-info {
      background-color: rgba(37, 37, 37, 0.4);
      color: #ffffff;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 12px 24px;

      .user-name {
        font-size: 18px;
        font-weight: 500;
      }

      .date-time {
        font-size: 16px;
        font-weight: normal;
        opacity: 0.72;
      }
    }
    .timeline-subject {
      background-color: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 32px;
      border-bottom-left-radius: 32px;
      padding: 12px 24px;
      margin-top: -4px;
    }
  }

  .see-all-timeline {
    border-radius: 99px;
    background-color: #ffffff;
    padding: 12px 16px 12px 24px;
    position: absolute;
    bottom: -71px;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .heading-task-content {
    &.has-badge {
      margin-top: 32px;
    }
  }
  .task-type-header-container {
    position: absolute;
    max-width: calc(100% - 48px);
    right: 30px;
    top: 13px;
  }
}
</style>
<style lang="scss">
.timeline-bar-container {
  .timeline-subject {
    .timeline-user-name {
      background-color: #0000000f;
      border-radius: 99px;
      padding: 2px 8px;
      font-weight: normal;
    }

    .task-emoji {
      border-radius: 6px;
      border: 1px solid #d2ddec;
      padding: 2px 4px;
      display: inline-block;
    }
  }
}
</style>
